import React, {Component} from 'react';
import './index.css'; /* optional for styling like the :hover pseudo-class */
import USAMap from "react-usa-map";
import 'bootstrap/dist/css/bootstrap.css';
import StateDataTable from '../components/StateDataTable';
import {Navigate} from "react-router-dom"
import StateSummary from '../components/StateSummary';
import StateDict from '../components/StateDict';
import CitySummary from '../components/CitySummary';
// import Pagation from '../components/Pagation'
import axios from "axios";
import {Puff} from 'react-loading-icons';
import Cookies from 'universal-cookie';
import API_ENDPOINT from "../config";

class App extends Component {

    constructor(props) {

        super(props);
        const web_cookies = new Cookies();
        // web_cookies.set('session-key', null, { path: '/' });
        // web_cookies.set('bearer', null, { path: '/' });

        this.state = {
            authenticated: false,
            verifying: true,
            page: 1,
            active_state: "ALL STATES",
            cookies: web_cookies,
            username: null,
            password: null,
            password_set: true,
            data: null,
            states_under_contract: ["MI", "WI", "PA"],
            invalid_credentials: false,
            width: 700,
            height: 500,
            markers: [],
            error: false

        };
        this.authenticated = false
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    updateDimensions() {
        if (window.innerWidth < 768) { // Consider mobile if screen width is less than 768px
            this.setState({authenticated: false})
            this.setState({width: 300, height: 300});
        } else {
            this.setState({width: 700, height: 500});
        }
    }

    verifySession = async () => {
        const baseURL = `${API_ENDPOINT}/verify_session`;

        try {
            await axios.get(baseURL, {
                headers: {
                    "session-key": this.state.cookies.get("session-key"),
                    "bearer": this.state.cookies.get("bearer"),
                }
            });

            this.setState({authenticated: true, verifying: false})
            return null;
        } catch (err) {
            console.log(err)
            this.setState({error: true})
            if (err.response === undefined) {
                this.setState({authenticated: false, verifying: false})
                return null;
            } 
            if (err.response.status === 403) {
                this.setState({authenticated: false, verifying: false})
            }
            console.log(`Err @ [ App.jsx - getStateData ] ::: ${err}`);
            return null;  // Return null or handle the error appropriately
        }
    };

    componentDidMount() {
        this.verifySession()
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    handlePageChange = async (page) => {
        this.setState({page});
        console.log("Current page is:", page);  // You can handle the page change here (e.g., fetch new data)
        await this.pagateState(page)
    };


    getUserData = async (new_state, page) => {
        const baseURL = `${API_ENDPOINT}/data`;

        try {
            const res = await axios.get(baseURL, {
                headers: {
                    "session-key": this.session_key,
                    "bearer": this.state.cookies.get("bearer"),
                    "state": new_state,
                    "page": page
                }
            });
            this.session_key = res.data["_session"]

            return res.data.data;  // Return the data directly
        } catch (err) {
            if (err.response.status === 403) {
                this.setState({authenticated: false})
            } else if (err.response.status === 429) {
                alert("Please slow down your requests. You have been rate limited")
                this.setState({loading: false})
            }

            console.log(`Err @ [ App.jsx - getStateData ] ::: ${err}`);
            this.setState({error: true})
            return null;  // Return null or handle the error appropriately
        }
    };

    handleExport = async () => {
        this.setState({loading: true});
        try {
            const response = await axios.get(`${API_ENDPOINT}/data`, {
                headers: {
                    "session-key": this.session_key,
                    "bearer": this.state.cookies.get("bearer"),
                    "state": this.state.new_state,
                    "page": 0
                }

            });
            this.state.cookies.set('session-key', response.data["_session"], {path: '/', secure: true})
            const bytes = new TextEncoder().encode(JSON.stringify(response.data.data));

            // Create a link element to download the file
            const url = window.URL.createObjectURL(new Blob([bytes], {
                type: "application/json;charset=utf-8"
            }));
            const a = document.createElement('a');
            a.href = url;
            a.download = 'exported_data.csv'; // Adjust filename as needed
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Cleanup the URL object
            window.URL.revokeObjectURL(url);
            this.setState({loading: false});
        } catch (error) {
            console.error('There was a problem with the export:', error);
            this.setState({loading: false});
            this.setState({error: true})
        }
    };

    getStateData = async (new_state) => {
        const baseURL = `${API_ENDPOINT}/state_data`;
        try {
            const res = await axios.get(baseURL, {
                headers: {
                    "session-key": this.session_key,
                    "bearer": this.state.cookies.get("bearer"),
                    "state": new_state,
                }
            });
            this.session_key = res.data["_session"]
            // this.session_key = res.data["_session"]
            return res.data.data;  // Return the data directly
        } catch (err) {
            if (err.response.status === 403) {
                this.setState({authenticated: false})
            }
            console.log(`Err @ [ App.jsx - getStateData ] ::: ${err}`);
            this.setState({error: true})
            return null;  // Return null or handle the error appropriately
        }
    };

    getCityData = async (new_state) => {
        const baseURL = `${API_ENDPOINT}/city_data`;
        try {
            const res = await axios.get(baseURL, {
                headers: {
                    "session-key": this.session_key,
                    "bearer": this.state.cookies.get("bearer"),
                    "state": new_state,
                }
            });
            this.session_key = res.data["_session"]
            return res.data.data;  // Return the data directly
        } catch (err) {
            if (err.response.status === 403) {
                this.setState({authenticated: false})
            }
            console.log(`Err @ [ App.jsx - getStateData ] ::: ${err}`);

            return null;  // Return null or handle the error appropriately
        }
    };

    getAllData = async (new_state) => {
        const baseURL = `${API_ENDPOINT}/map_data`;

        try {
            const res = await axios.get(baseURL, {
                headers: {
                    "session-key": this.session_key,
                    "bearer": this.state.cookies.get("bearer"),
                    "state": new_state,
                    "page": 1
                }
            });
            this.session_key = res.data["_session"]
            return res.data;  // Return the data directly
        } catch (err) {
            if (err.response.status === 403) {
                this.setState({authenticated: false})
            } else if (err.response.status === 429) {
                alert("Please slow down your requests. You have been rate limited")
                this.setState({loading: false})
            }

            console.log(`Err @ [ App.jsx - getStateData ] ::: ${err}`);

            return null;  // Return null or handle the error appropriately
        }
    };


    setActiveState = (new_state) => {
        this.setState({active_state: new_state});
    }

    pagateState = async (page) => {
        const new_state = this.state.new_state

        // Set loading to true before starting the async operation
        this.setState({loading: true});

        if (this.state.states_under_contract.includes(new_state)) {
            try {
                this.session_key = this.state.cookies.get('session-key')
                const user_response = await this.getUserData(new_state, page);
                if (user_response !== null && user_response !== undefined) {
                    this.setState({
                        data: user_response, active_state: StateDict[new_state], loading: false
                    })
                }
                this.state.cookies.set('session-key', this.session_key, {path: '/', secure: true})
            } catch (error) {
                this.setState({error: true})
                console.error('Error fetching pagation data:', error);
                // Set loading to false if there's an error
                this.setState({loading: false});
            }
        } else {
            alert(`${new_state} is not currently under contract with Campaign Social`);
            // Set loading to false if the state is not under contract
            this.setState({loading: false});
        }
    };

    Logout = () => {
        this.state.cookies.remove('session-key')
        this.state.cookies.remove('berer')
        this.setState({authenticated: false, cookies: null, verifying: false});
    }

    /* mandatory */
    mapHandler = async (event) => {
        const new_state = event.target.dataset.name;

        // Set loading to true before starting the async operation
        this.setState({loading: true});

        if (this.state.states_under_contract.includes(new_state)) {
            this.setState({new_state})
            try {
                this.session_key = this.state.cookies.get('session-key')
                const all_data = await this.getAllData(new_state);
                if (all_data !== null && all_data !== undefined) {
                    this.setState({
                        data: all_data.mongo_user_data,
                        active_state: StateDict[new_state],
                        state_data: all_data.state_data,
                        city_data: all_data.city_data,
                        max_pages: all_data.max_pages,
                        loading: false
                    })

                }
                this.state.cookies.set('session-key', this.session_key, {path: '/', secure: true})
                // Update state with the fetched data and the active state, and set loading to false
            } catch (error) {
                this.setState({error: true})
                console.error('Error fetching state data:', error);
                // Set loading to false if there's an error
                this.setState({loading: false});
            }
        } else {
            alert(`${new_state} is not currently under contract with Campaign Social`);
            // Set loading to false if the state is not under contract
            this.setState({loading: false});
        }
    };

    /* optional customization of filling per state and calling custom callbacks per state */
    statesCustomConfig = () => {
        return {
            "PA": {
                fill: "#CC0000"
            },
            "WI": {
                fill: "#CC0000"
            },
            "MI": {
                fill: "#CC0000"
            }
        };
    };

    render() {
        const {verifying, authenticated} = this.state;

        if (verifying) {
            return (
                <>
                </>
            )
        }

        return (
            <div className="App">
                {authenticated === true ?
                    <div className=''>
                        <div className='row data-header'>
                            <div className='col-11'>
                                {<p>{this.state.active_state}</p>}
                            </div>
                            <div className='col-1'>
                                <a className= 'logout' href="/" onClick={this.Logout}>SIGN OUT</a>
                            </div> 
                        </div>
                        <div className='row below'>
                            <div className='col-12 col-md-5'>
                                <USAMap customize={this.statesCustomConfig()} onClick={this.mapHandler}
                                        width={this.state.width} height={this.state.height} title={"US STATE MAP"}/>
                            </div>
                            <div className='col-12 col-md-7 pt-3 px-5'>
                                {this.state.data !== null && this.state.data !== undefined ?
                                    <StateDataTable rows={this.state.data} maxPages={this.state.max_pages}
                                                    onPageChange={this.handlePageChange}
                                                    handleExport={this.handleExport}/> :
                                    <div className='summary-text'>
                                        <h2 className='summary-p'>Select a State to Begin</h2>
                                    </div>
                                }
                                {this.state.loading ? (
                                    <>
                                        <Puff stroke="#CC0100" strokeOpacity={1} speed={1.75} height={88}/>
                                        <p>Loading</p>
                                    </>
                                ) : (
                                    <div>
                                        {/* Render your main content here, e.g., the data or active state UI */}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='row pt-5'>
                                <div className='row'>
                                    {this.state.data !== null ?
                                        <>
                                            <StateSummary rows={this.state.state_data}/>
                                            <CitySummary rows={this.state.city_data}/>
                                        </> :
                                        <>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div> : (
                        <Navigate replace to={'/login'}/>
                    )
                }

            </div>
        );
    }
}

export default App;
