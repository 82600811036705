import React from 'react';
import {MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';
import Pagation from "./Pagation";

export default function StateDataTable({rows, onPageChange, maxPages, handleExport}) {

    const handlePageChange = (page) => {
        onPageChange(page);  // Return the page number to the parent component
    };

    return (
        <div className="scrollable-table-container shadow-lg">
            {rows.length > 0 ?
                <button onClick={handleExport} className="btn btn-data mx-3 mt-4">
                    Export
                </button>
                :
                <button onClick={handleExport} className="btn btn-data disabled mx-3 mt-4">
                    Export
                </button>
            }
            <div className="left mx-3 mt-1">
              <Pagation maxPages={maxPages} onPageChange={handlePageChange}/>
            </div>
            <MDBTable striped bordered hover className='max-width'>
                <MDBTableHead>
                    <tr>
                        <th scope='col'>F_NAME</th>
                        <th scope='col'>L_NAME</th>
                        <th scope='col'>URL</th>
                        <th scope='col'>CATHOLIC</th>
                        <th scope='col'>CITY</th>
                        <th scope='col'>STATE</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {rows.map(row => (
                        <tr key={row["_id"]}>
                            <td>{row.first_name}</td>
                            <td>{row.last_name}</td>
                            <td><a href={row.facebook_url} target="blank"><b>Facebook</b></a></td>
                            <td>{row["interests"].slice(0, 50) + "..."}</td>
                            <td>{row.registration_address_city}</td>
                            <td>{row.state}</td>
                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>
        </div>
    );
}