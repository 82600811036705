import Nav from 'react-bootstrap/Nav';
import NavbarB from 'react-bootstrap/Navbar';

export default function Navbar() {
  return (
    <NavbarB className="nav-setings" variant="dark" expand="lg" fixed="top">
      <Nav className="" fixed="top" defaultActiveKey="/">
        <Nav.Item>
          <Nav.Link className='nav-link sticky-nav' href="/" >
              <div className= 'nav-title'>PROMETHEUS</div>
              <div className="nav-subtitle">A CampaignSocial Product</div>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </NavbarB>
  );
}