
import React from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

export default function CitySummary(props) {
    console.log(props)
    return (
        <div className="scrollable-table-container pt-2 px-5">
        <MDBTable striped bordered hover className='max-width'>
            <MDBTableHead>
                <tr>
                    <th className='user-header' scope='col'>User ID</th>
                    <th className='user-header' scope='col'>Requests</th>
                    <th className='user-header' scope='col'>Access Points</th>
                    <th className='user-header' scope='col'>Most Frequent</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>

            {props.rows.map(row => (
                <tr>
                    <td>{row["user_id"]}</td>
                    <td>{row["request"]}</td>
                    <td>{row["access_points"].length}</td>
                    <td>{row["access_points"].length > 0 ? row["access_points"].sort((a, b) => b.hit_count - a.hit_count)[0]['ip'] : "None"}</td>

                </tr>
            ))}

            </MDBTableBody>
        </MDBTable>
        </div>
    );
}