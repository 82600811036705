// Filename - App.js

import React from "react";
import Navbar from "./components/Navbar";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages";
import Login from "./pages/login";
import Admin from "./pages/admin";
import AdminLogin from "./pages/admin_login"
function App() {
    return (
        <Router>
            <Navbar/>
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/login" element={<Login/>}/>
                <Route exact path="/admin" element={<Admin/>}/>
                <Route exact path="/admin_login" element={<AdminLogin/>}/>


            </Routes>
        </Router>
    );
}

export default App;
