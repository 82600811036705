
import React from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

export default function CitySummary(props) {
    return (
        <div className="scrollable-table-container">
        <MDBTable striped bordered hover>
            <MDBTableHead>
                <tr>
                    <th className='summary-header red-bg' scope='col'>CITY</th>
                    <th className='summary-header blue-bg' scope='col'># PEOPLE</th>
                    <th className='summary-header blue-bg' scope='col'># REPUBLICANS</th>
                    <th className='summary-header blue-bg' scope='col'># LOW PARTICIPATION</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
            
            {props.rows.map(row => (
                <tr key={row["_id"]}>
                    <td>{row['_id']}</td>
                    <td>{row.count}</td>
                    <td>{row.lean_gop + row.strong_gop}</td>
                    <td>{row.low_voter_frequency_count}</td>
                </tr>
            ))}
            
            </MDBTableBody>
        </MDBTable>
        </div>
    );
}