import React, {Component} from "react";
import Cookies from "universal-cookie";
import L from "leaflet";
import markerIcon from "../map_marker.png";
import axios from "axios";
import "./admin.css";
import 'leaflet/dist/leaflet.css';
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import UserTable from "../components/UserTable";
import {Navigate} from "react-router-dom";
import API_ENDPOINT from "../config";

class Admin extends Component {
    constructor(props) {
        super(props);
        const web_cookies = new Cookies();

        this.state = {
            authenticated: false,
            verifying: true,
            active_state: "ALL STATES",
            cookies: web_cookies,
            username: null,
            password: null,
            password_set: true,
            data: null,
            states_under_contract: ["MI", "WI", "PA"],
            invalid_credentials: false,
            width: 700,
            height: 500,
            markers: [],
            user_data: [],
            showTyping: true,
            fadeOut: false
        };

        this.updateDimensions = this.updateDimensions.bind(this);
        this.verifyAdminSession = this.verifyAdminSession.bind(this);
        this.getPositions = this.getPositions.bind(this);

        this.customIcon = new L.Icon({
            iconUrl: markerIcon,
            iconSize: [32, 32], // size of the icon
            iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -32], // point from which the popup should open relative to the iconAnchor
        });
    }

    updateDimensions() {
        if (window.innerWidth < 768) { // Consider mobile if screen width is less than 768px
            this.setState({authenticated: false, width: 300, height: 300});
        } else {
            this.setState({width: 700, height: 500});
        }
    }

    async verifyAdminSession() {
        const baseURL = `${API_ENDPOINT}/verify_admin_session`;

        try {
            await axios.get(baseURL, {
                headers: {
                    "session-key": this.state.cookies.get("session-key"),
                    "bearer": this.state.cookies.get("bearer"),
                    "admin-key": this.state.cookies.get("admin-key"),
                }
            });

            this.setState({admin_authenticated: true, verifying: false});
        } catch (err) {
            console.log(err);
            if (err.response && err.response.status === 403) {
                this.setState({authenticated: false, verifying: false});
            }
            console.log(`Err @ [ App.jsx - getStateData ] ::: ${err}`);
        }
    }

    async getPositions() {
        const baseURL = `${API_ENDPOINT}/admin`;

        try {
            const res = await axios.get(baseURL, {
                headers: {
                    "session-key": this.state.cookies.get("session-key"),
                    "bearer": this.state.cookies.get("bearer"),
                    "admin-key": this.state.cookies.get("admin-key")
                }
            });
            this.state.cookies.set('session-key', res.data["_session-key"], {path: '/', secure: true});
            let location_list = [];
            for (let user in res.data.data) {
                for (let ip in res.data.data[user]["access_points"]) {
                    let location_data = res.data.data[user]["access_points"][ip]["location_data"];
                    if (location_data !== "None") {
                        location_list.push({
                            "lat": location_data["lat"], "long": location_data["lon"],
                            "message": res.data.data[user]["access_points"][ip]["ip"] + "<br />" + res.data.data[user]["user_id"]
                        });
                    }
                }
            }
            this.setState({user_id: res.data.user})
            this.setState({markers: location_list, user_data: res.data.data});
        } catch (err) {
            if (err.response && err.response.status === 403) {
                console.log("Unauthenticated");
            }
            console.log(`Err @ [ App.jsx - getStateData ] ::: ${err}`);
        }
    }

    componentDidMount() {
        this.verifyAdminSession();
        this.getPositions();
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);

        // Handle typing and fade out effects
        setTimeout(() => {
            this.setState({fadeOut: true});
        }, 4000); // Duration of typing effect

        setTimeout(() => {
            this.setState({showTyping: false});
        }, 5000); // Duration before showing the actual content
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        const {showTyping, fadeOut, user_id} = this.state;
        if (this.state.verifying) {
            return null; // or a loading spinner if needed
        }

        if (this.state.admin_authenticated) {
            return (
                <div>
                    {showTyping ? (
                        <div className={`welcome-container ${fadeOut ? 'fade-to-black' : ''}`}>
                            <span className={`typing-text ${fadeOut ? 'fade-out' : ''}`}>
                                Welcome {user_id}. Enjoy your stay :)
                            </span>
                        </div>
                    ) : (
                        <div className="Admin">
                            <div className='row pt-5'>
                                <div className='col-12 col-md-12 map-box px-5'>
                                    <MapContainer
                                        center={[40, 0]}
                                        zoom={3}
                                        scrollWheelZoom={true}
                                        style={{height: '100%', width: '100%'}}
                                    >
                                        <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        {this.state.markers.length > 0 &&
                                            this.state.markers.map((marker, index) => (
                                                <Marker
                                                    key={index}
                                                    position={[marker.lat, marker.long]}
                                                    icon={this.customIcon}
                                                >
                                                    <Popup>
                                                        <div dangerouslySetInnerHTML={{__html: marker.message}}/>
                                                    </Popup>
                                                </Marker>
                                            ))}
                                    </MapContainer>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-12 pt-3' style={{background: "black"}}>
                                    {this.state.user_data.length > 0 ? (
                                        <UserTable rows={this.state.user_data}/>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return <Navigate replace to={'/admin_login'}/>;
        }
    }
}

export default Admin;
