
import React from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

export default function StateSummary(props) {

    return (
        <div className="scrollable-table-container">
        <MDBTable striped bordered hover>
            <MDBTableHead>
                <tr>
                    <th className='summary-header red-bg' scope='col'>TOTAL RECORDS</th>
                    <th className='summary-header blue-bg' scope='col'>REPUBLICAN PERCENTAGE</th>
                    <th className='summary-header blue-bg' scope='col'>LOW VOTING PARTICIPATION PERCENTAGE</th>
                    <th className='summary-header blue-bg' scope='col'>SWING PERCENTAGE</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                <tr>
                    <td>{props.rows.total_records}</td>
                    <td>{props.rows.total_records > 0 ? Number((props.rows.total_gop / props.rows.total_records * 100).toFixed(2)) : 0}%</td>
                    <td>{props.rows.total_records > 0 ? Number((props.rows.low_percentage_voters / props.rows.total_records * 100).toFixed(2)) : 0}%</td>
                    <td>{props.rows.total_records > 0 ? Number((props.rows.swing / props.rows.total_records * 100).toFixed(2)) : 0}%</td>
                </tr>
            </MDBTableBody>
        </MDBTable>
        </div>
    );
}